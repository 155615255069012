<template>
  <v-card flat tile class="px-0 py-8">
    <v-row class="grey lighten-5">

      <v-col>
        <v-btn icon color="secondary" @click="toggleExpandConditionKeys(!expand)">
          <v-icon>{{ expand === true ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
        <span class="pt-2 text-h6">{{ $t('condAdmin.condition.conditionKeyID.headline') }}</span>
      </v-col>
    </v-row>

    <v-row class="grey lighten-5">
      <v-col>

        <v-card v-if="expand === true" flat tile>
          <v-card-text class="pl-0 pb-5">
            <v-row v-if="!readonly && expand === true">
              <v-col>
                <span class="pt-2 pl-8 text-h8">{{ $t('condAdmin.condition.conditionKeyID.choices') }}</span>
              </v-col>
            </v-row>
            <v-row class="conditionTable">
              <v-col v-for="(item, index) in criteria" :key="index" cols="12" sm="4" md="4" lg="4" xl="2">
                <v-checkbox :label="item.label" v-model="item.checkBoxvalue" @change="setChoice(index, $event)"
                  :disabled=item.disabled :readonly=readonly hide-details></v-checkbox>
              </v-col>
            </v-row>
            <!-- Tabelle zur Auswahl -->
            <v-row v-if="choiceCounters > 0" no-gutters>
              <table class="text-center conditionKeyIdTable v-data-table" cellspacing=0 cellpadding=0>
                <tr>
                  <th class="text-center firstRow px-8" v-for="(item, index) in selectedCriteria" :key="index">
                    <span>{{ item }}</span>
                  </th>
                  <th class="px-4 firstRow">{{ $t('condAdmin.condition.conditionKey') }}</th>
                </tr>
                <tr v-for="(item, index) in getSelectedCriteriaValues" :key="JSON.stringify(item)">

                  <td class="text-center firstRow px-8" v-for="(name, index2) in item" :key="index2">
                    <span v-for="(keyWord, index3) in Object.keys(name)" :key="index3">{{ name[keyWord] }}</span>

                  </td>
                  <td class="align-center text-center px-4 firstRow">
                    <span v-if="readonly == true">{{ $filters.formatNumber(getConditionKey(index, item), null, NULLVALUE)
                    }}
                    </span>
                    <EditField v-if="readonly !== true" @change="setConditionKey(index, item, $event)"
                      :value="getConditionKey(index, item)" :placeholder="$t('base.value')" hideDetails type="text"
                      class=interestField :readonly=readonly />
                  </td>
                </tr>

              </table>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-card>
</template>

<script lang="js">
import condAdminConditionMixin from '../../../js/condAdminConditionMixin.js';
// import { refreshProductTypeList, refreshProductList, getCalcTypeDurations } from '../js/condAdminHelper.js';
import EditField from '@/common/EditField';

export default {
  name: 'ConditionKeyIDYareto',
  components: {
    EditField
  },
  mixins: [condAdminConditionMixin],
  props: {
    attributes: { type: Array, required: true },
    durationList: { type: Array, required: true },
    products: { type: Array, required: true },
    interestLines: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    conditionKeyParams: { type: Object, required: false }
  },
  data () {
    return {
      criteria: [
        { label: this.$t('condAdmin.condition.conditionKeyID.checkboxes.objectType'), checkBoxvalue: false, disabled: false, name: 'Objektart', conditionValues: [] },
        { label: this.$t('condAdmin.condition.conditionKeyID.checkboxes.objectSubType'), checkBoxvalue: false, disabled: false, name: 'Unterart', conditionValues: [] },
        { label: this.$t('condAdmin.condition.conditionKeyID.checkboxes.interestLine'), checkBoxvalue: false, disabled: false, name: 'Betragsband', conditionValues: [] },
        { label: this.$t('condAdmin.condition.conditionKeyID.checkboxes.duration'), checkBoxvalue: false, disabled: false, name: 'Laufzeit', conditionValues: [] },
        { label: this.$t('condAdmin.condition.conditionKeyID.checkboxes.commissionOptimized'), checkBoxvalue: false, disabled: false, name: 'Provisionsoptimiert', conditionValues: [] },
        { label: this.$t('condAdmin.condition.conditionKeyID.checkboxes.bestInterest'), checkBoxvalue: false, disabled: false, name: 'Bestzins', conditionValues: [] }
      ],
      choiceCounter: 0,
      selectedCriteriaValues: [],
      conditionKeys: this.conditionKeyParams === null ? {} : JSON.parse(JSON.stringify(this.conditionKeyParams)),
      maxConditionKeys: 0,
      expand: false
    };
  },
  computed: {
    selectedCriteria () {
      let selectedCriteria = [];
      for (let cr of this.criteria) {
        if (cr.checkBoxvalue) {
          selectedCriteria.push(cr.label);
        }
      }
      return selectedCriteria;
    },
    /* -------------------------------------------------------------------------------------------- */
    getSelectedCriteriaValues () {
      let sCValues = [];
      let tempCounter = 0;

      for (let cr of this.criteria) {
        if (cr.checkBoxvalue) {
          tempCounter++;
          this.setCriteriaValues('add', cr, tempCounter);
        }
      }

      let tempArray = [];
      for (let sc of this.selectedCriteriaValues) {
        tempArray = [];
        for (let cr of this.criteria) {
          if (cr.checkBoxvalue) {
            let vl = {};
            vl[cr.name] = sc.find(el => el.criteria === cr.name).name;
            vl['suffix'] = sc.find(el => el.criteria === cr.name).suffix;// TEST
            tempArray.push(vl);
          }
        }
        // doppelte Einträge überspringen
        if (sCValues.find(el => JSON.stringify(el) === JSON.stringify(tempArray)) === undefined) {
          sCValues.push(tempArray);
        }
      }
      this.setMaxConditionKey(sCValues.length);
      this.setConditionKeyCriteria();
      return sCValues;
    },
    choiceCounters () {
      return this.choiceCounter;
    }
    /* -------------------------------------------------------------------------------------------- */
  },
  watch: {
    attributes: {
      handler: function () {
        this.init();
        this.refreshCriteriaTable();
        this.refreshConditionKeys();
      },
      deep: true
    },
    products: {
      handler: function () {
        this.init();
        this.refreshCriteriaTable();
        this.refreshConditionKeys();
      },
      deep: true
    },
    interestLines: {
      handler: function () {
        this.init();
        this.refreshCriteriaTable();
        this.refreshConditionKeys();
      },
      deep: true
    }

    /*
    $props: {
      handler: function () {
        this.init();
        this.refreshCriteriaTable();
        // this.refreshConditionKeys();
      },
      deep: true
    } */
  },
  mounted () {
    this.init();
    this.initKeyTable();
  },
  methods: {
    // --------------------------------------------------------------------------
    initKeyTable () {
      // Checkboxen setzen
      if (JSON.stringify(this.conditionKeys) !== '{}') {
        let ckv = this.conditionKeys.conditionKeyValues[0];
        if (ckv != null) {
          for (const key of Object.keys(ckv)) {
            let index = this.criteria.findIndex(el => el.name === key);
            if (index > -1) {
              this.setChoice(index, true, true);
            }
          }
        }
      }
    },
    // --------------------------------------------------------------------------
    init () {
      for (let cr of this.criteria) {
        cr.conditionValues = [];
        switch (cr.name) {
          case 'Objektart':
            for (let pr of this.products) {
              // alle Einschlüße aufnehmen
              if (pr.isInclude === 1) {
                if (pr.productTypeUid !== null) {
                  // alle Unterarten gewählt
                  if (pr.subProductTypeUid === null) {
                    let temp = this.$store.state.condAdmin.productTypeList.find(el => el.uid === pr.productTypeUid);
                    if (temp !== null) {
                      let tempArray = [];
                      for (let sPT of temp.subProductTypes) {
                        tempArray.push({ Unterart: sPT.uid });
                        // cr.conditionValues.push({ criteria: cr.name, uid: pr.uid, name: pr.name, dependsOn: sPT.uid });
                      }
                      cr.conditionValues.push({ criteria: cr.name, uid: pr.productTypeUid, name: this.$store.state.condAdmin.productTypeList.find(el => el.uid === pr.productTypeUid).name, suffix: '', originalValue: { productTypeUid: pr.productTypeUid }, dependsOn: tempArray });
                    }
                  } else {
                    let dependsOn = { Unterart: pr.subProductTypeUid };
                    cr.conditionValues.push({ criteria: cr.name, uid: pr.productTypeUid, name: this.$store.state.condAdmin.productTypeList.find(el => el.uid === pr.productTypeUid).name, suffix: '', originalValue: { productTypeUid: pr.productTypeUid }, dependsOn: dependsOn });
                  }
                } else {
                  for (let pt of this.$store.state.condAdmin.productTypeList) {
                    let tempArray = [];
                    for (let sPT of pt.subProductTypes) {
                      tempArray.push({ Unterart: sPT.uid });
                      // cr.conditionValues.push({ criteria: cr.name, uid: pt.uid, name: pt.name, dependsOn: sPT.uid });
                    }
                    cr.conditionValues.push({ criteria: cr.name, uid: pt.uid, name: pt.name, suffix: '', originalValue: { productTypeUid: pt.uid }, dependsOn: tempArray });
                  }
                }
              }
            }
            for (let pr of this.products) {
              // die Ausschlüße wieder entfernen
              if (pr.isInclude === 0) {
                // nicht alle Objektarten wurden gewählt
                if (pr.productTypeUid !== null) {
                  // alle Unterarten gewählt
                  if (pr.subProductTypeUid === null) {
                    cr.conditionValues = cr.conditionValues.filter(el => el.uid !== pr.productTypeUid);
                  }
                }
              }
            }
            // console.debug('Objektart', JSON.stringify(cr.conditionValues));
            break;
          case 'Unterart':
            for (let pr of this.products) {
              if (pr.isInclude === 1) {
                // PKW, Motorrad oder Caravan ausgewählt aber nicht alle
                if (pr.productTypeUid !== null) {
                  let temp = this.$store.state.condAdmin.productTypeList.find(ele => ele.uid === pr.productTypeUid);
                  // Alle ausgewählt
                  if (pr.subProductTypeUid === null) {
                    for (let spt of temp.subProductTypes) {
                      let dependsOn = [];
                      dependsOn.push({ Objektart: pr.productTypeUid });
                      cr.conditionValues.push({ criteria: cr.name, uid: spt.uid, name: spt.name, suffix: '', originalValue: { subProductTypeUid: spt.uid }, dependsOn: dependsOn });
                    }
                  } else {
                    let dependsOn = [{ Objektart: pr.productTypeUid }];
                    cr.conditionValues.push({ criteria: cr.name, uid: pr.subProductTypeUid, name: temp.subProductTypes.find(el => el.uid === pr.subProductTypeUid).name, suffix: '', originalValue: { subProductTypeUid: pr.subProductTypeUid }, dependsOn: dependsOn });
                  }
                } else {
                  // alle ausgewählt, dann kann auch subProductTypeUID nur ALLE sein
                  for (let pt of this.$store.state.condAdmin.productTypeList) {
                    if (pt.subProductTypes) {
                      for (let spt of pt.subProductTypes) {
                        let dependsOn = [{ Objektart: pt.uid }];
                        cr.conditionValues.push({ criteria: cr.name, uid: spt.uid, name: spt.name, suffix: '', originalValue: { subProductTypeUid: spt.uid }, dependsOn: dependsOn });
                      }
                    }
                  }
                }
              }
              for (let pr of this.products) {
                // alle Unterarten entfernen die nicht ausgewählt wurden
                if (pr.isInclude === 0) {
                  // nicht alle ausgewählt, alle macht auch keinen Sinn
                  if (pr.productTypeUid !== null) {
                    // alle Unterarten gewählt
                    if (pr.subProductTypeUid === null) {
                      let temp = this.$store.state.condAdmin.productTypeList.find(el => el.uid === pr.productTypeUid);
                      for (let spt of temp.subProductTypes) {
                        cr.conditionValues = cr.conditionValues.filter(el => el.uid !== spt.uid);
                      }
                    } else { // nicht alle Unterarten werden ausgeschlossen
                      cr.conditionValues = cr.conditionValues.filter(el => el.uid !== pr.subProductTypeUid);
                    }
                  }
                }
              }
            }
            // console.debug('Unterart', JSON.stringify(cr.conditionValues));
            break;
          case 'Betragsband':
            for (let il of this.interestLines) {
              let tempDependsOn = [];
              let temp = this.attributes.filter(el => el.minCredit === il.minCredit && el.maxCredit === il.maxCredit);
              for (let tmp of temp) {
                if (tempDependsOn.find(el => el === tmp.interestRate) === undefined) {
                  if (tmp.commission.length > 0) {
                    tempDependsOn.push({ Provisionsoptimiert: tmp.interestRate });
                  }
                  tempDependsOn.push({ Bestzins: tmp.interestRate });
                }
              }
              // cr.conditionValues.push({ criteria: cr.name, uid: { minCredit: il.minCredit, maxCredit: il.maxCredit }, name: this.getRangeText(il.minCredit, il.maxCredit, this.currency), dependsOn: tempDependsOn });
              cr.conditionValues.push({ criteria: cr.name, uid: this.getRangeText(il.minCredit, il.maxCredit, this.currency), name: this.getRangeText(il.minCredit, il.maxCredit, this.currency), suffix: '', originalValue: { minCredit: il.minCredit, maxCredit: il.maxCredit }, dependsOn: tempDependsOn });
            }
            // console.debug('Betragsband', JSON.stringify(cr.conditionValues));
            break;
          case 'Laufzeit':
            for (let lz = 0; lz < this.durationList.length; lz++) {
              let tempAttributes = this.attributes.filter(att => att.duration === this.durationList[lz] && att.commissionInterest !== true);
              if (tempAttributes !== null && tempAttributes.length > 0) {
                for (let attr of tempAttributes) {
                  // if (cr.conditionValues.find(el => el.name === attr.duration) == null) {
                  let dependsOn = [{ Bestzins: attr.interestRate }];
                  cr.conditionValues.push({ criteria: cr.name, uid: attr.duration, name: attr.duration, suffix: '', originalValue: { duration: attr.duration }, dependsOn: dependsOn });
                  // }
                }
              }
              /*
              for (let attr of this.attributes) {
                if (attr.commissionInterest !== true) {
                // if (cr.conditionValues.find(el => el.name === attr.duration) == null) {
                  let dependsOn = [{ Bestzins: attr.interestRate }];
                  cr.conditionValues.push({ criteria: cr.name, uid: attr.duration, name: attr.duration, suffix: '', dependsOn: dependsOn });
                // }
                }
              } */
            }
            // console.debug('Laufzeit', JSON.stringify(cr.conditionValues));
            break;
          case 'Provisionsoptimiert':
            for (let lz = 0; lz < this.durationList.length; lz++) {
              let tempAttributes = this.attributes.filter(att => att.duration === this.durationList[lz]);
              if (tempAttributes !== null && tempAttributes.length > 0) {
                // for (let attr of this.attributes) {
                for (let attr of tempAttributes) {
                  if (attr.commission.length > 0) {
                    let tempDependsOn = [];
                    tempDependsOn.push({ Laufzeit: attr.duration });
                    // tempDependsOn.push({ Betragsband: { minCredit: attr.minCredit, maxCredit: attr.maxCredit } });
                    tempDependsOn.push({ Betragsband: this.getRangeText(attr.minCredit, attr.maxCredit, this.currency) });
                    if (attr.commissionInterest !== true) {
                      cr.conditionValues.push({ criteria: cr.name, uid: attr.interestRate, name: 'Bestzins', suffix: '', originalValue: { interestRate: attr.interestRate }, dependsOn: tempDependsOn });
                    } else {
                      cr.conditionValues.push({ criteria: cr.name, uid: attr.interestRate, name: attr.interestRate, suffix: ' %', originalValue: { interestRate: attr.interestRate }, dependsOn: tempDependsOn });
                    }
                    // }
                  }
                }
              }
            }
            // console.debug('Provisionsoptimiert', JSON.stringify(cr.conditionValues));
            break;
          case 'Bestzins':
            for (let lz = 0; lz < this.durationList.length; lz++) {
              let tempAttributes = this.attributes.filter(att => att.duration === this.durationList[lz] && att.commissionInterest !== true);
              if (tempAttributes !== null && tempAttributes.length > 0) {
                // for (let attr of this.attributes) {
                for (let attr of tempAttributes) {
                  if (cr.conditionValues.find(el => el === attr.interestRate) == null) {
                    let tempDependsOn = [];
                    tempDependsOn.push({ Laufzeit: attr.duration });
                    // tempDependsOn.push({ Betragsband: { minCredit: attr.minCredit, maxCredit: attr.maxCredit } });
                    tempDependsOn.push({ Betragsband: this.getRangeText(attr.minCredit, attr.maxCredit, this.currency) });
                    cr.conditionValues.push({ criteria: cr.name, uid: attr.interestRate, name: attr.interestRate, suffix: ' %', originalValue: { interestRate: attr.interestRate }, dependsOn: tempDependsOn });
                  }
                }
              }
            }
            // console.debug('Bestzins', JSON.stringify(cr.conditionValues));
            break;
        }
      }
    },
    setDirty () {
      this.$emit('setDirty');
    },

    setChoice (choice, value, noReset = false) {
      if (value) {
        switch (this.choiceCounter) {
          case 1:
            this.choiceCounter++;
            this.criteria[choice].checkBoxvalue = value;
            for (let cr of this.criteria) {
              if (cr.checkBoxvalue === false) {
                cr.disabled = true;
              }
            }
            break;
          case 0:
            this.choiceCounter++;
            this.criteria[choice].checkBoxvalue = value;
            if (this.criteria[choice].name === 'Provisionsoptimiert') {
              this.criteria.find(element => element.name === 'Bestzins').disabled = true;
            }
            if (this.criteria[choice].name === 'Bestzins') {
              this.criteria.find(element => element.name === 'Provisionsoptimiert').disabled = true;
            }
            break;
        }
      } else {
        this.choiceCounter--;
        this.criteria[choice].checkBoxvalue = false;
        for (let cr of this.criteria) {
          cr.disabled = false;
        }
        if (this.criteria.find(element => element.name === 'Provisionsoptimiert').checkBoxvalue === true) {
          this.criteria.find(element => element.name === 'Bestzins').disabled = true;
        }
        if (this.criteria.find(element => element.name === 'Bestzins').checkBoxvalue === true) {
          this.criteria.find(element => element.name === 'Provisionsoptimiert').disabled = true;
        }
        // this.setCriteriaValues('remove', this.criteria[choice], this.choiceCounter);
      }
      if (!noReset) {
        this.conditionKeys = {};// zurücksetzen
        this.setDirty();
      }
    },
    // ------------------------------------------------------------------------------------------------------
    setCriteriaValues (action, criteria, crCounter) {
      let tempSelectedCriteriaValues = [];
      switch (action) {
        case 'remove':
          if (crCounter === 0) {
            this.selectedCriteriaValues = [];
          } else {
            for (let cr of this.criteria) {
              if (cr.checkBoxvalue === true) {
                this.selectedCriteriaValues = [];
                Object.assign(this.selectedCriteriaValues, cr.conditionValues);
                break;
              }
            }
          }
          break;
        case 'add':
          let temp = [];
          if (crCounter === 1) {
            this.selectedCriteriaValues = [];
            for (let ccV of criteria.conditionValues) {
              temp = [];
              temp.push(ccV);
              tempSelectedCriteriaValues.push(temp);
            }
            Object.assign(this.selectedCriteriaValues, tempSelectedCriteriaValues);
          } else {
            for (let ccV of criteria.conditionValues) {
              if (ccV.dependsOn !== '') {
                let tempCounter = 0;
                if (Array.isArray(ccV.dependsOn)) {
                  for (let dependsOn of ccV.dependsOn) {
                    let temp = this.fillSelectCriteria(dependsOn, ccV);
                    if (temp.length > 0) {
                      tempSelectedCriteriaValues.push(temp);
                      tempCounter = 1;
                    }
                  }
                  if (tempCounter === 0) {
                    for (let scV of this.selectedCriteriaValues) {
                      let tempArray = [...scV];
                      tempArray.push(ccV);
                      tempSelectedCriteriaValues.push(tempArray);
                    }
                  }
                } else {
                  let temp = this.fillSelectCriteria(ccV.dependsOn, ccV);
                  if (temp.length > 0) {
                    tempSelectedCriteriaValues.push(temp);
                  } else {
                    for (let scV of this.selectedCriteriaValues) {
                      let tempArray = [...scV];
                      tempArray.push(ccV);
                      tempSelectedCriteriaValues.push(tempArray);
                    }
                  }
                }
              } else {
                for (let scV of this.selectedCriteriaValues) {
                  let tempArray = [...scV];
                  tempArray.push(ccV);
                  tempSelectedCriteriaValues.push(tempArray);
                }
              }
            }
            this.selectedCriteriaValues = [];
            Object.assign(this.selectedCriteriaValues, tempSelectedCriteriaValues);
          }
          break;
      }
    },
    fillSelectCriteria (dependsOn, selectCriteria) {
      let temp;
      for (let sCV of this.selectedCriteriaValues) {
        for (const key of sCV.keys()) {
          if (dependsOn.hasOwnProperty(sCV[key].criteria)) {
            temp = sCV.find(el => dependsOn[sCV[key].criteria] === el.uid);
          }

          if (temp !== undefined) {
            break;
          }
        }

        if (temp !== undefined) {
          break;
        }
      }
      let tempArray = [];

      // if (temp !== undefined && temp.length > 0) {
      if (temp !== undefined) {
        tempArray.push(temp);
        tempArray.push(selectCriteria);
      }
      return tempArray;
    },
    // ------------------------------------------------------------------------------------
    setMaxConditionKey (conditionKeysCount) {
      this.conditionKeys.maxConditionKeys = conditionKeysCount;
    },
    setConditionKeyCriteria () {
      this.conditionKeys.selectedCriteria = [];
      for (let cr of this.criteria) {
        if (cr.checkBoxvalue) {
          this.conditionKeys.selectedCriteria.push(cr.name);
        }
      }
    },
    // ----------------------------------------------------------------------------------------
    setConditionKey (index, item, value) {
      let temp = {};
      if (value.length < 1) {
        let ckIndex = this.conditionKeys.conditionKeyValues.findIndex(el => el.index === index);
        this.conditionKeys.conditionKeyValues[ckIndex].conditionKey = null;
      } else {
        if (typeof this.conditionKeys.conditionKeyValues === 'undefined') {
          this.conditionKeys.conditionKeyValues = [];
        }
        let ckIndex = this.conditionKeys.conditionKeyValues.findIndex(el => el.index === index);

        if (ckIndex > -1) {
          this.conditionKeys.conditionKeyValues[ckIndex].conditionKey = value;
        } else {
          temp.conditionKey = value;
          temp.index = index;
          temp.originalValues = {};
          for (let it of item) {
            // entfernen von suffix
            for (let itKey of Object.keys(it)) {
              if (itKey !== 'suffix') {
                temp[itKey] = it[itKey];
                let i = this.criteria.findIndex(el => el.name === itKey);
                if (i > -1) {
                  temp.originalValues[itKey] = this.criteria[i].conditionValues.find(el => el.name === it[itKey]).originalValue;
                }
              }
            }
          }
          this.conditionKeys.conditionKeyValues.push(temp);
        }
      }
      this.setDirty();
    },
    // ----------------------------------------------------------------------------------------
    getConditionKey (index, item) {
      let rValue = null;
      if (typeof this.conditionKeys.conditionKeyValues !== 'undefined') {
        if (this.conditionKeys.conditionKeyValues.length > 0 && (this.conditionKeys.conditionKeyValues.length - 1) >= index) {
          let tempConditionKeys = {};
          Object.assign(tempConditionKeys, this.conditionKeys);
          for (let it of item) {
            for (const [key, value] of Object.entries(it)) {
              if (key !== 'suffix') {
                tempConditionKeys.conditionKeyValues = tempConditionKeys.conditionKeyValues.filter(el => el[key] === value);
              }
            }
          }

          if (tempConditionKeys.conditionKeyValues.length > 0) {
            rValue = tempConditionKeys.conditionKeyValues[0].conditionKey;
          }
        }
      } else {
        console.debug('if (JSON.stringify(this.conditionKeys) !== {}');
      }
      return rValue;
    },
    // -----------------------------------------------------------
    refreshConditionKeys () {
      let selectedValues = [];
      Object.assign(selectedValues, this.getSelectedCriteriaValues);
      // console.debug('selectedValues', JSON.stringify(selectedValues));
      // array anlegen mit Key und Value != key=='suffix'
      let sVArray = [];
      for (let scV of selectedValues) {
        let tempObject = {};
        for (let temp of scV) {
          delete temp.suffix;
          // anpassen an conditionKeys
          for (let tp of Object.keys(temp)) {
            tempObject[tp] = temp[tp];
          }
        }
        // anpassen an conditionKeys
        sVArray.push(tempObject);
      }
      let saveConditionKeys = {};
      Object.assign(saveConditionKeys, this.conditionKeys);
      let tempConditionKeys = {};
      this.conditionKeys.conditionKeyValues = [];
      let counter = 0;
      for (let svA of sVArray) {
        Object.assign(tempConditionKeys, saveConditionKeys);
        if (tempConditionKeys.conditionKeyValues != null && tempConditionKeys.conditionKeyValues.length > 0) {
          for (const [key, value] of Object.entries(svA)) {
            tempConditionKeys.conditionKeyValues = tempConditionKeys.conditionKeyValues.filter(el => el[key] === value);
          }
        }

        if (tempConditionKeys.conditionKeyValues != null && tempConditionKeys.conditionKeyValues.length > 0) {
          tempConditionKeys.conditionKeyValues[0].index = counter;
          counter++;
          this.conditionKeys.conditionKeyValues = this.conditionKeys.conditionKeyValues.concat(tempConditionKeys.conditionKeyValues);
        } else { // eintrag nicht gefunden, neu anlegen
          let temp = {};
          temp.conditionKey = null;
          temp.index = counter;
          temp.originalValues = {};
          for (let itKey of Object.keys(svA)) {
            temp[itKey] = svA[itKey];
            let i = this.criteria.findIndex(el => el.name === itKey);
            if (i > -1) {
              temp.originalValues[itKey] = this.criteria[i].conditionValues.find(el => el.name === svA[itKey]).originalValue;
            }
          }

          this.conditionKeys.conditionKeyValues.push(temp);
          counter++;
        }
      }
    },

    // -----------------------------------------------------------
    refreshCriteriaTable () {
      this.selectedCriteriaValues = [];
      this.choiceCounter = 0;
      for (let cr of this.criteria) {
        if (cr.checkBoxvalue === true) {
          // console.debug('cr', JSON.stringify(cr));
          this.choiceCounter++;
          this.setCriteriaValues('add', cr, this.choiceCounter);
        }
      }
    },
    toggleExpandConditionKeys (expand) {
      this.expand = expand;
    },

    /* *******************
    *
    * save
    *
    ******************* */
    getConditionsKeyData () {
      // console.debug('getConditionsKeyData');
      return this.conditionKeys;
    }

  }
};
</script>

<style scoped>
.conditionTable {
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 25px;
  padding: 0;
}

.fixCol {
  position: absolute !important;
  width: 150px;
  left: 32px;
  top: auto;
}

.firstRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-top: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.conditionTable td.first,
.conditionTable th.first {
  border-left: 1px solid var(--v-grey-lighten3) !important;
}

.conditionKeyIdTable {
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 25px;
  padding: 0;
  margin-top: 25px;

}

.conditionKeyIdTable td.first,
.conditionKeyIdTable th.first {
  border-left: 1px solid var(--v-grey-lighten3) !important;
}

.conditionKeyIdTable .firstRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-top: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  border-left: 1px solid var(--v-grey-lighten3) !important;
}

.conditionKeyIdTable .interestField.v-input,
.comTable .v-input {
  margin-top: -12px !important;
}

.interestField {
  width: 150px !important;
  max-width: 150px !important;
}
</style>
